import React, { useRef, useState, useEffect, useContext } from "react";
import {
    SttButton,
    SttLoading,
    SttGrid,
    SttAutocomplete,
    SttInput,
    SttCircularProgress,
    SttTranslateHook,

} from '@stt-componentes/core';
import {
    Formik,
    FastField,
    Field
} from 'formik';
import HttpStatus from 'http-status-codes';
import * as yup from 'yup';
import axios from 'axios';
import { getHeaders } from '../../../request';
import {
    MOVIMENTACAO,
    PROCEDIMENTO_SOLICITADO,
} from './fieldNames';
import { makeStyles } from '@material-ui/core/styles';

const validationSchema = (strings) => {
    return yup.object().shape({
        [PROCEDIMENTO_SOLICITADO]: yup
            .object().shape({
                id: yup.number(),
                descricao: yup.string(),
                ativo: yup.boolean()
            })
            .nullable()
            .required(strings.campoObrigatorio)
    });
}

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    }

}));

const OperacaoInformarProcedimentoSolicitado = ({ callback, callbackSucesso, id, alerta, ...props }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const schema = validationSchema(strings);
    const formRef = useRef();
    const TFD_API_BASE_URL = global.gConfig.url_base_tfd;

    const initialValues = {
        procedimento_solicitado: null,
        movimentacao: null
    };

    const {
        setMostrarAlerta,
        setTituloAlerta,
        setMensagemAlerta,
        setTipoAlerta,
        setOpcoesAlerta,
        setOnCloseAlerta,
    } = alerta;

    const [procedimentos, setProcedimentos] = useState(null);
    const [carregado, setCarregado] = useState(false);

    useEffect(() => {
        axios
            .get(`${TFD_API_BASE_URL}/procedimento`, { headers: getHeaders() })
            .then((response) => {
                setCarregado(true);
                if (response.data) {
                    const { itens } = response.data.data;
                    setProcedimentos(itens);
                }
            })
            .catch(err => {
                setProcedimentos([]);
                setTituloAlerta(strings.erro);
                setMensagemAlerta(strings.erroRecuperacaoProcedimentos);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                        }
                    }
                ]);
                setOnCloseAlerta(() => () => {
                    setMostrarAlerta(false);
                });
                setMostrarAlerta(true);
            });
    }, []);

    const closeAlerta = (resetForm, idProcesso) => {
        setMostrarAlerta(false);
        resetForm({ values: initialValues });
        callbackSucesso(idProcesso);
    }

    return (
        <>
            {
                carregado ?
                    <Formik
                        innerRef={formRef}
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={(dataProcedimento, { setSubmitting, resetForm, }) => {
                            dataProcedimento.id = id;
                            if (dataProcedimento.movimentacao) {
                                dataProcedimento.movimentacao = dataProcedimento.movimentacao.trim().toUpperCase();
                            }

                            setSubmitting(true);

                            axios.post(`${TFD_API_BASE_URL}/informar-procedimento-solicitado`, dataProcedimento, { headers: getHeaders() })
                                .then((response) => {

                                    const { data } = response;

                                    if (response.status === HttpStatus.OK) {
                                        setTituloAlerta(strings.sucesso);
                                        setMensagemAlerta(data.message ? data.message : strings.sucessoOperacao);
                                        setTipoAlerta('success');
                                        setOpcoesAlerta([
                                            {
                                                title: strings.ok,
                                                onClick: () => {
                                                    closeAlerta(resetForm, data.id);
                                                }
                                            }
                                        ]);
                                        setOnCloseAlerta(() => () => {
                                            closeAlerta(resetForm, data.id);
                                        });
                                        setMostrarAlerta(true);

                                    }


                                })
                                .catch(err => {
                                    const { response } = err;
                                    let mensagem = strings.mensagemErroGeral;

                                    if (response) {
                                        if (response.status === HttpStatus.BAD_REQUEST) {
                                            const { data } = response;
                                            let arrMensagem = [];
                                            data.errors.forEach(error => {
                                                arrMensagem.push(`- ${error.message}`);
                                            });
                                            if (arrMensagem) {
                                                mensagem = arrMensagem.join('\n');
                                            }
                                        }
                                    }

                                    setTituloAlerta(strings.erro);
                                    setMensagemAlerta(mensagem);
                                    setTipoAlerta('error');
                                    setOpcoesAlerta([
                                        {
                                            title: strings.ok,
                                            onClick: () => {
                                                setMostrarAlerta(false);
                                            }
                                        }
                                    ]);
                                    setOnCloseAlerta(() => () => {
                                        setMostrarAlerta(false);
                                        setSubmitting(false);
                                    });
                                    setMostrarAlerta(true);
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }

                        }
                    >
                        {
                            ({
                                isSubmitting,
                                handleSubmit,
                                resetForm,
                            }) => {
                                return (
                                    <form onSubmit={handleSubmit} noValidate>

                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                <Field name={PROCEDIMENTO_SOLICITADO}>
                                                    {({
                                                        field: { name, value, onBlur },
                                                        form: { setFieldValue },
                                                        meta,
                                                    }) => (
                                                        <SttAutocomplete
                                                            inputprops={{
                                                                name: name,
                                                                label: strings.procedimentoSolicitado,
                                                                required: true,
                                                                error: meta.touched && meta.error ? meta.error : undefined
                                                            }}
                                                            getOptionLabel={option => (option && option.descricao) || ''}
                                                            getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.id === valueOptionSelected.id}
                                                            options={procedimentos}
                                                            carregando={!procedimentos}
                                                            value={value}
                                                            onBlur={onBlur}
                                                            onChange={(e, item) => setFieldValue(PROCEDIMENTO_SOLICITADO, item || null)}
                                                        />
                                                    )}
                                                </Field>
                                            </SttGrid>
                                            <SttGrid item xs={12}>
                                                <FastField name={MOVIMENTACAO}>
                                                    {({
                                                        field,
                                                        meta
                                                    }) => (
                                                        <SttInput
                                                            multiline
                                                            rows={5}
                                                            {...field}
                                                            label={strings.movimentacao}
                                                            inputProps={{ style: { textTransform: "uppercase" } }}
                                                        />
                                                    )}
                                                </FastField>
                                            </SttGrid>
                                            <SttLoading
                                                open={isSubmitting}
                                                text={strings.salvandoMensagemEspera}
                                            />
                                        </SttGrid>
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12}>
                                                <SttButton
                                                    type="button"
                                                    variant="contained"
                                                    color="primary"
                                                    nomarginleft="true"
                                                    disabled={isSubmitting}
                                                    onClick={() => {

                                                        setTituloAlerta(strings.confirmacao);
                                                        setMensagemAlerta(strings.mensagemConfirmacaoProcedimentoSolicitado)
                                                        setTipoAlerta('confirmation');
                                                        setOpcoesAlerta([
                                                            {
                                                                title: strings.sim,
                                                                onClick: () => {
                                                                    handleSubmit();
                                                                }
                                                            },
                                                            {
                                                                title: strings.nao,
                                                                onClick: () => {
                                                                    setMostrarAlerta(false);
                                                                }
                                                            }
                                                        ]);
                                                        setOnCloseAlerta(() => () => {
                                                            setMostrarAlerta(false);
                                                        });

                                                        setMostrarAlerta(true);
                                                    }}
                                                >
                                                    {strings.confirmar}
                                                </SttButton>
                                                <SttButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        resetForm({ values: initialValues });
                                                    }}
                                                >
                                                    {strings.limpar}
                                                </SttButton>
                                                <SttButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        resetForm({ values: initialValues });
                                                        callback();
                                                    }}
                                                >
                                                    {strings.fechar}
                                                </SttButton>
                                            </SttGrid>
                                        </SttGrid>
                                    </form>
                                )
                            }
                        }
                    </Formik>

                    : (
                        <div className={classes.carregando}>
                            <SttCircularProgress color="primary" />
                        </div>
                    )
            }
        </>
    );
}

export default OperacaoInformarProcedimentoSolicitado;
