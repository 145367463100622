import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { SITUACAO_PROCESSO } from '../../common/Constants';
import Legenda from './legenda';
import Chip from '@material-ui/core/Chip';
import Detalhes from './detalhes';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import {
    SttText,
    SttCircularProgress,
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttHidden,
    SttTranslateHook,
    SttSeeButton
} from '@stt-componentes/core';
import { IconButton, Tooltip } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import axios from 'axios';
import { getHeaders } from '../../request';

const { LABEL_CURTA, COR, LABEL } = SITUACAO_PROCESSO;

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    laudoPorVencer: {
        backgroundColor: '#F7FC75'
    },
    laudoVencido: {
        backgroundColor: '#D88080'
    },
    chip: {
        '& span': {
            whiteSpace: 'pre-wrap'
        },
        padding: theme.spacing(0.2),
        height: 'auto',
        textAlign: 'center'
    },
    tableCell: {
        // textAlign: 'center'
    },
    heading: {
        marginTop: theme.spacing(2.5),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
        textAlign: 'center'
    },
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        NUMERO_PEDIDO: 'numero_pedido',
        DATA: 'data',
        CIDADE_ORIGEM: 'cidade_uf_origem',
        CIDADE_DESTINO: 'cidade_uf_destino',
        DATA_HORA_ATENDIMENTO: 'data_hora_atendimento',
        PROCEDIMENTO_SOLICITADO: 'procedimento_solicitado',
        PACIENTE: 'paciente',
        FUNCIONARIO_REGULADOR: 'nome_regulador',
        SITUACAO: 'situacao',
        QTT_SOLICITACAO_PASSAGEM: 'qtt_solicitacao_passagem'
    }
}

const handleCorFundo = (row) => {
    if (!row.laudo_vencido && !row.laudo_vencendo) {
        return '#FFF';
    }

    if (row.laudo_vencido) {
        return '#FFE4E4';
    }

    if (row.laudo_vencendo) {
        return '#FFFEE5';
    }

}

const handleAvatarChip = (row, strings) => {
    if (row.pendencia) {
        return <ErrorOutlineIcon fontSize='small' style={{ color: '#F5222D' }}></ErrorOutlineIcon>
    }

    if (LABEL_CURTA[row.situacao] === strings.aguardando) {
        return <IconButton fontSize='small'><img src="/static/search_activity.svg" /></IconButton>
    }

    return null;
}

const tratarSolicitacoes = (solicitacoes) => {
    solicitacoes.map((solicitacao) => {
        let situacaoFormatada = LABEL[solicitacao.situacao];

        if (solicitacao.nome_regulador) {
            situacaoFormatada += ` - ${solicitacao.nome_regulador}`;
        }

        if (solicitacao.nome_avaliador) {
            situacaoFormatada += ` - ${solicitacao.nome_avaliador}`;
        }

        if (solicitacao.id_cidade_origem && solicitacao.id_tfd_cadastro_cidade_agendadora) {
            situacaoFormatada += ` - ${solicitacao.descricao_cidade_agendadora}`;
        }

        solicitacao.situacaoFormatada = situacaoFormatada;
    });

    return solicitacoes;
}

const HistoricoPaciente = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { adicionarAba, idPaciente } = props;
    const classes = useStyles();

    //Filtros
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
    const [countPesquisa, setCount] = useState(global.gConfig.pagination.count);
    const [page, setPage] = useState(global.gConfig.pagination.start);
    const [order, setOrder] = useState(ORDENACAO.DIRECAO.ASC);
    const [orderBy, setOrderBy] = useState(null);
    const [buscar, setBuscar] = useState(false);

    //Solicitação
    const [solicitacoes, setSolicitacoes] = useState([]);
    const [totalRegistros, setTotalRegistros] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.start);
        setBuscar(true);
    };

    const handleClickSort = (campo) => {
        const isAsc = (orderBy === campo) && (order === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setOrder(newOrder);
        setOrderBy(campo);
        setBuscar(true);
    }

    const handleVisualizarSolicitacao = (row) => {
        adicionarAba({
            titulo: `${strings.solicitacao} - ${row.sequencial}`,
            conteudo: Detalhes,
            id: row.id,
            adicionarAba,
            idAba: row.id,
            esconderBotoes: true
        });
    };

    const callbackBusca = (dados) => {
        console.log(dados)
        setTotalRegistros(parseInt(dados.totalRegistros));
        setSolicitacoes(dados.itens);
        setBuscar(false);
    }

    useEffect(() => {
        if (buscar) {
            const filtros = {
                idPaciente
            };
            const offset = (page * countPesquisa);

            filtros.count = countPesquisa;
            filtros.start = offset;
            filtros.sort = orderBy;
            filtros.direction = order;

            setBuscaEmAndamento(true);
            axios.get(`${global.gConfig.url_base_tfd}/solicitacao/historico`, { params: filtros, headers: getHeaders() })
                .then((response) => {
                    const dados = response.data.data;
                    dados.itens = tratarSolicitacoes(dados.itens);
                    callbackBusca(dados);
                })
                .catch(err => {
                    callbackBusca({
                        totalRegistros: 0,
                        itens: []
                    });
                })
                .finally(() => {
                    setBuscaEmAndamento(false);
                });
        }
    }, [buscar]);

    //Toda vez que o componente for renderizado, realiza a busca
    useEffect(() => {
        setBuscar(true);
    }, []);

    return (
        <>
            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttHidden smDown>
                        <caption colSpan={13}><Legenda /></caption>
                    </SttHidden>
                    <SttHidden mdUp>
                        <caption width="96%"><Legenda /></caption>
                    </SttHidden>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden smDown>
                                <SttTableCell width="5%" className={classes.tableCell} sortDirection={orderBy === ORDENACAO.CAMPOS.DATA ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.DATA}
                                        direction={orderBy === ORDENACAO.CAMPOS.DATA ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA)}>
                                        {strings.data}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell className={classes.tableCell} sortDirection={orderBy === ORDENACAO.CAMPOS.CIDADE_ORIGEM ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.CIDADE_ORIGEM}
                                        direction={orderBy === ORDENACAO.CAMPOS.CIDADE_ORIGEM ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CIDADE_ORIGEM)}>
                                        {strings.origem}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell className={classes.tableCell} sortDirection={orderBy === ORDENACAO.CAMPOS.CIDADE_DESTINO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.CIDADE_DESTINO}
                                        direction={orderBy === ORDENACAO.CAMPOS.CIDADE_DESTINO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CIDADE_DESTINO)}>
                                        {strings.destino}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell className={classes.tableCell} sortDirection={orderBy === ORDENACAO.CAMPOS.FUNCIONARIO_REGULADOR ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.FUNCIONARIO_REGULADOR}
                                        direction={orderBy === ORDENACAO.CAMPOS.FUNCIONARIO_REGULADOR ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.FUNCIONARIO_REGULADOR)}>
                                        {strings.regulador}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell className={classes.tableCell} sortDirection={orderBy === ORDENACAO.CAMPOS.PACIENTE ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.PACIENTE}
                                        direction={orderBy === ORDENACAO.CAMPOS.PACIENTE ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.PACIENTE)}>
                                        {strings.paciente}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell className={classes.tableCell} sortDirection={orderBy === ORDENACAO.CAMPOS.PROCEDIMENTO_SOLICITADO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.PROCEDIMENTO_SOLICITADO}
                                        direction={orderBy === ORDENACAO.CAMPOS.PROCEDIMENTO_SOLICITADO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.PROCEDIMENTO_SOLICITADO)}>
                                        {strings.procedimento}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell className={classes.tableCell} sortDirection={orderBy === ORDENACAO.CAMPOS.DATA_HORA_ATENDIMENTO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.DATA_HORA_ATENDIMENTO}
                                        direction={orderBy === ORDENACAO.CAMPOS.DATA_HORA_ATENDIMENTO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_HORA_ATENDIMENTO)}>
                                        {strings.atendimento}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell className={classes.tableCell} sortDirection={orderBy === ORDENACAO.CAMPOS.NUMERO_PEDIDO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.NUMERO_PEDIDO}
                                        direction={orderBy === ORDENACAO.CAMPOS.NUMERO_PEDIDO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NUMERO_PEDIDO)}>
                                        {strings.pedido}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell className={classes.tableCell} align="center" sortDirection={orderBy === ORDENACAO.CAMPOS.QTT_SOLICITACAO_PASSAGEM ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.QTT_SOLICITACAO_PASSAGEM}
                                        direction={orderBy === ORDENACAO.CAMPOS.QTT_SOLICITACAO_PASSAGEM ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.QTT_SOLICITACAO_PASSAGEM)}>
                                        {strings.solicitacoesPassagem}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="9%" className={classes.tableCell} sortDirection={orderBy === ORDENACAO.CAMPOS.SITUACAO ? order : false}>
                                    <SttTableSortLabel
                                        active={orderBy === ORDENACAO.CAMPOS.SITUACAO}
                                        direction={orderBy === ORDENACAO.CAMPOS.SITUACAO ? order : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.SITUACAO)}>
                                        {strings.situacao}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell style={{ position: 'sticky', right: 0, backgroundColor: 'white', backgroundClip: 'padding-box', textAlign: 'center' }}>
                                    {strings.visualizar}
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden mdUp>
                                <SttTableCell width="100%" colSpan="2">Solicitações de Passagens</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            buscaEmAndamento
                                ?
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={11} align="center">
                                        <SttCircularProgress />
                                    </SttTableCell>
                                </SttTableRow>
                                :
                                (
                                    solicitacoes.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={11} align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        solicitacoes.map((row, index) => (
                                            <SttTableRow key={index} style={{ backgroundColor: handleCorFundo(row) }}>
                                                <SttHidden smDown>
                                                    <SttTableCell className={classes.tableCell}>{row.data_tfd_formatada}</SttTableCell>
                                                    <SttTableCell className={classes.tableCell}>{row.cidade_uf_origem}</SttTableCell>
                                                    <SttTableCell className={classes.tableCell}>{row.cidade_uf_destino}</SttTableCell>
                                                    <SttTableCell className={classes.tableCell}>{row.nome_regulador}</SttTableCell>
                                                    <SttTableCell className={classes.tableCell}>{row.paciente}</SttTableCell>
                                                    <SttTableCell className={classes.tableCell}>{row.procedimento_solicitado}</SttTableCell>
                                                    <SttTableCell className={classes.tableCell}>{row.data_hora_atendimento_formatada}</SttTableCell>
                                                    <SttTableCell className={classes.tableCell}>{row.numero_pedido}</SttTableCell>
                                                    <SttTableCell className={classes.tableCell} align="center">{row.qtt_solicitacao_passagem}</SttTableCell>
                                                    <SttTableCell className={classes.tableCell}>
                                                        <Tooltip title={row.situacaoFormatada}>
                                                            <Chip
                                                                avatar={handleAvatarChip(row, strings)}
                                                                className={classes.chip}
                                                                style={{ backgroundColor: COR[row.situacao] }}
                                                                label={LABEL_CURTA[row.situacao]}
                                                            />
                                                        </Tooltip>
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttHidden mdUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small">
                                                            <b>{strings.data}:</b> {row.data_tfd_formatada}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.origem}:</b> {row.cidade_uf_origem}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.destino}:</b> {row.cidade_uf_destino}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.regulador}:</b> {row.nome_regulador}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.paciente}:</b> {row.paciente}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.procedimento}:</b> {row.procedimento_solicitado}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.atendimento}:</b> {row.data_hora_atendimento_formatada}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.pedido}:</b> {row.numero_pedido}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.solicitacoesPassagem}:</b> {row.qtt_solicitacao_passagem}
                                                        </SttText>
                                                        <Chip
                                                            avatar={handleAvatarChip(row, strings)}
                                                            className={classes.chip}
                                                            style={{ marginTop: '4px', backgroundColor: COR[row.situacao] }}
                                                            label={row.situacaoFormatada}
                                                        />
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center" style={{ position: 'sticky', right: 0, backgroundColor: handleCorFundo(row), backgroundClip: 'padding-box' }}>
                                                    <SttSeeButton
                                                        id={`btn-ver-solicitacao-${index}`}
                                                        onClick={() => {
                                                            handleVisualizarSolicitacao(row);
                                                        }}
                                                    />
                                                </SttTableCell>
                                            </SttTableRow>
                                        ))
                                )
                        }
                    </SttTableBody>
                </SttTable>
            </TableContainer>
            {
                solicitacoes.length > 0 ?
                    (
                        <SttTablePagination rowsPerPageOptions={[10, 20, 40]}
                            component="div"
                            count={totalRegistros}
                            rowsPerPage={countPesquisa}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={strings.linhasPorPagina}
                            labelDisplayedRows={({ from, to, count }) => `${from} - ${to} ${strings.de} ${count}`}
                        />
                    ) : null
            }
        </>
    )
}

export default HistoricoPaciente;