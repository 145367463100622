import React, { useRef, useContext } from "react";
import {
    SttButton,
    SttLoading,
    SttGrid,
    SttInput,
    SttTranslateHook
} from '@stt-componentes/core';
import {
    Formik,
    FastField
} from 'formik';
import HttpStatus from 'http-status-codes';
import * as yup from 'yup';
import axios from 'axios';
import { getHeaders } from '../../../request';
import {
    PENDENCIA
} from './fieldNames';

const validationSchema = (strings) => {
    return yup.object().shape({
        [PENDENCIA]: yup
            .string()
            .min(10, 'Justificativa inválida')
            .nullable()
            .required(strings.campoObrigatorio)
    });
}

const OperacaoPendencia = ({ callback, callbackSucesso, callbackSucessoPendencia, id, alerta, pendenciaAlterecao = null }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);
    const formRef = useRef();
    const TFD_API_BASE_URL = global.gConfig.url_base_tfd;

    const initialValues = {
        pendencia: pendenciaAlterecao || ''
    };

    const {
        setMostrarAlerta,
        setTituloAlerta,
        setMensagemAlerta,
        setTipoAlerta,
        setOpcoesAlerta,
        setOnCloseAlerta
    } = alerta;

    const closeAlerta = () => {
        setMostrarAlerta(false);
        if (callbackSucessoPendencia) {
            callbackSucessoPendencia();
            return;
        }
        callbackSucesso();
    }

    const finalizarPendencia = (setSubmitting) => {
        setSubmitting(true);
        axios.post(`${TFD_API_BASE_URL}/solicitacao/finalizar-pendencia`, { id }, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                if (response.status === HttpStatus.OK) {
                    setTituloAlerta(strings.sucesso);
                    setMensagemAlerta(data.message ? data.message : strings.sucessoOperacao);
                    setTipoAlerta('success');
                    setOpcoesAlerta([
                        {
                            title: strings.ok,
                            onClick: () => {
                                closeAlerta();
                            }
                        }
                    ]);
                    setOnCloseAlerta(() => () => {
                        closeAlerta();
                    });
                    setMostrarAlerta(true);
                }
            })
            .catch(err => {
                const { response } = err;
                let mensagem = strings.mensagemErroGeral;

                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem) {
                            mensagem = arrMensagem.join('\n');
                        }
                    }
                }

                setTituloAlerta(strings.erro);
                setMensagemAlerta(mensagem);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                        }
                    }
                ]);
                setOnCloseAlerta(() => () => {
                    setMostrarAlerta(false);
                });
                setMostrarAlerta(true);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(dataPost, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                dataPost.pendencia = dataPost.pendencia.trim().toUpperCase();
                dataPost.id = id;
                dataPost.edicao = !!pendenciaAlterecao;

                axios.post(`${TFD_API_BASE_URL}/solicitacao/sinalizar-pendencia`, dataPost, { headers: getHeaders() })
                    .then((response) => {

                        const { data } = response;
                        if (response.status === HttpStatus.OK) {
                            setTituloAlerta(strings.sucesso);
                            setMensagemAlerta(data.message ? data.message : strings.sucessoOperacao);
                            setTipoAlerta('success');
                            setOpcoesAlerta([
                                {
                                    title: strings.ok,
                                    onClick: () => {
                                        closeAlerta();
                                    }
                                }
                            ]);
                            setOnCloseAlerta(() => () => {
                                closeAlerta();
                            });
                            setMostrarAlerta(true);
                        }
                    })
                    .catch(err => {
                        const { response } = err;
                        let mensagem = strings.mensagemErroGeral;

                        if (response) {
                            if (response.status === HttpStatus.BAD_REQUEST) {
                                const { data } = response;
                                let arrMensagem = [];
                                data.errors.forEach(error => {
                                    arrMensagem.push(`- ${error.message}`);
                                });
                                if (arrMensagem) {
                                    mensagem = arrMensagem.join('\n');
                                }
                            }
                        }

                        setTituloAlerta(strings.erro);
                        setMensagemAlerta(mensagem);
                        setTipoAlerta('error');
                        setOpcoesAlerta([
                            {
                                title: strings.ok,
                                onClick: () => {
                                    setMostrarAlerta(false);
                                }
                            }
                        ]);
                        setOnCloseAlerta(() => () => {
                            setMostrarAlerta(false);
                        });
                        setMostrarAlerta(true);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        >
            {
                ({
                    isSubmitting,
                    setSubmitting,
                    handleSubmit,
                    resetForm,
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate style={{ overflow: 'hidden' }}>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <FastField name={PENDENCIA}>
                                        {({
                                            field,
                                            meta
                                        }) => (
                                            <SttInput
                                                multiline
                                                rows={5}
                                                required={true}
                                                {...field}
                                                label={strings.pendencia}
                                                inputProps={{ style: { textTransform: "uppercase" } }}
                                                error={meta.touched && meta.error ? true : false}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                <SttLoading
                                    open={isSubmitting}
                                    text={strings.salvandoMensagemEspera}
                                />
                            </SttGrid>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12}>
                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        nomarginleft="true"
                                        disabled={isSubmitting}
                                    >
                                        {strings.salvar}
                                    </SttButton>
                                    {
                                        !!pendenciaAlterecao &&
                                        <SttButton
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            disabled={isSubmitting}
                                            onClick={() => finalizarPendencia(setSubmitting)}
                                        >
                                            {strings.finalizarPendencia}
                                        </SttButton>
                                    }
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            resetForm({ values: initialValues });
                                            callback();
                                        }}
                                    >
                                        {strings.fechar}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                        </form>
                    )
                }
            }
        </Formik>
    );
}

export default OperacaoPendencia;