import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { SttTranslateHook, SttHidden } from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    circulo: {
        height: '20px',
        width: '20px',
        borderRadius: '50%',
        display: 'inline-block',
        border: '1px solid black'
    },
    label: {
        alignSelf: 'center', 
        marginLeft: '5px'
    }
}));

const Legenda = ({...props}) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    return (
        <>
            <SttHidden xsDown>
                <div style={{display: 'flex', gap:'5%'}}>
                    <div style={{display: 'flex'}}>
                        <span className={classes.circulo} style={{backgroundColor: '#FFFEE5'}}/>
                        <label style={{alignSelf: 'center', marginLeft: '5px'}}>{strings.laudoVencerMenos45Dias}</label>
                    </div>
                    
                    <div style={{display: 'flex', marginLeft:'10px'}}>
                        <span className={classes.circulo} style={{backgroundColor: '#FFE4E4'}}/>
                        <label style={{alignSelf: 'center', marginLeft: '5px'}}>{strings.laudoVencido}</label>
                    </div>
                </div>
            </SttHidden>
            <SttHidden smUp>
                <div style={{display: 'flex'}}>
                    <span className={classes.circulo} style={{backgroundColor: '#FFFEE5'}}/>
                    <label style={{alignSelf: 'center', marginLeft: '5px'}}>{strings.laudoVencerMenos45Dias}</label>
                </div>
                
                <div style={{display: 'flex', marginTop: '5px'}}>
                    <span className={classes.circulo} style={{backgroundColor: '#FFE4E4'}}/>
                    <label style={{alignSelf: 'center', marginLeft: '5px'}}>{strings.laudoVencido}</label>
                </div>
            </SttHidden>
        </>
    );

}

export default Legenda;