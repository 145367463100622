import React, { useState, useEffect, useContext } from 'react';

import {
    SttModal, SttTranslateHook
} from '@stt-componentes/core';

import { TIPO_OPERACAO } from '../../../common/Constants';
import Devolucao from './devolver';
import Cancelamento from './cancelar';
import Negacao from './negar';
import ArquivarProcesso from './arquivarProcesso';
import Pendencia from './pendencia';
import Encaminhamento from './encaminhar';
import EncaminhamentoTfdEstadual from './encaminharEstadual';
import InformarNumeroCNRAC from './informarNumeroCNRAC';
import Regulacao from './regular';
import ComprovanteComparecimento from './comprovanteComparecimento';
import InformarProcedimentoSolicitado from './informarProcedimentoSolicitado';
import InativarComprovanteComparecimento from './inativarComprovanteComparecimento';

const Operacoes = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const {
        id,
        idComprovanteComparecimento,
        tipoOperacao,
        callbackDetalhes,
        open,
        setOpen,
        voltarPesquisa,
        tipoProcesso,
        situacao,
        alerta,
        maxWidth = 'lg',
        ...others
    } = props;

    const [titulo, setTitulo] = useState('');

    const callback = () => {
        setOpen(!open);
    }

    const callbackSucesso = () => {
        callback();
        voltarPesquisa();
    }

    const switchOperacao = () => {
        switch (tipoOperacao) {
            case TIPO_OPERACAO.DEVOLUCAO:
                return <Devolucao
                    id={id}
                    callback={callback}
                    alerta={alerta}
                    callbackSucesso={callbackSucesso}
                    situacao={situacao}
                />
            case TIPO_OPERACAO.CANCELAMENTO:
                return <Cancelamento
                    id={id}
                    callback={callback}
                    alerta={alerta}
                    callbackSucesso={callbackSucesso}
                    situacao={situacao}
                />
            case TIPO_OPERACAO.NEGACAO:
                return <Negacao
                    id={id}
                    callback={callback}
                    alerta={alerta}
                    callbackSucesso={callbackSucesso}
                    situacao={situacao}
                />
            case TIPO_OPERACAO.ENCAMINHAMENTO:
                return <Encaminhamento
                    id={id}
                    callback={callback}
                    tipoProcesso={tipoProcesso}
                    alerta={alerta}
                    callbackSucesso={callbackSucesso}
                    situacao={situacao}
                />
            case TIPO_OPERACAO.ENCAMINHAMENTO_TFD_ESTADUAL:
                return <EncaminhamentoTfdEstadual
                    id={id}
                    callback={callback}
                    tipoProcesso={tipoProcesso}
                    alerta={alerta}
                    callbackSucesso={callbackSucesso}
                    situacao={situacao}
                />
            case TIPO_OPERACAO.INFORMAR_NUMERO_CNRAC:
                return <InformarNumeroCNRAC
                    id={id}
                    callback={callback}
                    tipoProcesso={tipoProcesso}
                    alerta={alerta}
                    callbackSucesso={callbackSucesso}
                    situacao={situacao}
                />
            case TIPO_OPERACAO.REGULAR:
                return <Regulacao
                    id={id}
                    callback={callback}
                    tipoProcesso={tipoProcesso}
                    alerta={alerta}
                    callbackSucesso={callbackSucesso}
                    situacao={situacao}
                />
            case TIPO_OPERACAO.ANEXAR_COMPROVANTE_COMPARECIMENTO:
                return <ComprovanteComparecimento
                    id={id}
                    callback={callback}
                    tipoProcesso={tipoProcesso}
                    alerta={alerta}
                    callbackSucesso={callbackSucesso}
                    situacao={situacao}
                />
            case TIPO_OPERACAO.INFORMAR_PROCEDIMENTO_SOLICITADO:
                return <InformarProcedimentoSolicitado
                    id={id}
                    callback={callback}
                    tipoProcesso={tipoProcesso}
                    alerta={alerta}
                    callbackSucesso={(idNovoProcesso) => {
                        callback();
                        callbackDetalhes(idNovoProcesso);
                    }}
                />
            case TIPO_OPERACAO.INATIVAR_COMPROVANTE_COMPARECIMENTO:
                return <InativarComprovanteComparecimento
                    idComprovanteComparecimento={idComprovanteComparecimento}
                    callback={callback}
                    tipoProcesso={tipoProcesso}
                    alerta={alerta}
                    callbackSucesso={callbackSucesso}
                    situacao={situacao}
                />
            case TIPO_OPERACAO.ARQUIVAR_PROCESSO:
                return <ArquivarProcesso
                    id={id}
                    callback={callback}
                    alerta={alerta}
                    callbackSucesso={callbackSucesso}
                    situacao={situacao}
                />
            case TIPO_OPERACAO.SINALIZAR_PENDENCIA:
                return <Pendencia
                    id={id}
                    alerta={alerta}
                    callback={callback}
                    callbackSucesso={callbackSucesso}
                    {...others}
                />
        }
    }

    useEffect(() => {
        switch (tipoOperacao) {
            case TIPO_OPERACAO.DEVOLUCAO:
                setTitulo(strings.devolucao);
                break;
            case TIPO_OPERACAO.CANCELAMENTO:
                setTitulo(strings.cancelamento);
                break;
            case TIPO_OPERACAO.NEGACAO:
                setTitulo(strings.negacao);
                break;
            case TIPO_OPERACAO.ENCAMINHAMENTO:
            case TIPO_OPERACAO.ENCAMINHAMENTO_TFD_ESTADUAL:
                setTitulo(strings.encaminhamento);
                break;
            case TIPO_OPERACAO.INFORMAR_NUMERO_CNRAC:
                setTitulo(strings.informarNumeroCnrac);
                break;
            case TIPO_OPERACAO.REGULAR:
                setTitulo(strings.regulacao);
                break;
            case TIPO_OPERACAO.ANEXAR_COMPROVANTE_COMPARECIMENTO:
                setTitulo(strings.anexarComprovanteComparecimento);
                break;
            case TIPO_OPERACAO.INFORMAR_PROCEDIMENTO_SOLICITADO:
                setTitulo(strings.informarProcedimentoSolicitado);
                break;
            case TIPO_OPERACAO.INATIVAR_COMPROVANTE_COMPARECIMENTO:
                setTitulo(strings.inativarComprovanteComparecimento);
                break;
            case TIPO_OPERACAO.ARQUIVAR_PROCESSO:
                setTitulo(strings.arquivarProcesso);
                break;
            case TIPO_OPERACAO.SINALIZAR_PENDENCIA:
                setTitulo(strings.pendencia);
                break;
        }
    }, [tipoOperacao]);

    return (
        <SttModal
            title={titulo}
            open={open}
            maxWidth={maxWidth}
            outModalClose={callback}
            iconClose={callback}
            fullWidth={true}
            children={
                switchOperacao()
            }
        />
    );
}

export default Operacoes;