import React, { useState, useRef, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { Formik, Field, FastField } from 'formik';
import { estadoInicial } from './estadoInicial';
import { getHeaders } from '../../request';
import axios from 'axios';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import moment from 'moment';
import { SITUACAO_PROCESSO, SITUACAO_PROCESSO_FILTRO, situacao, PERFIS } from '../../common/Constants';
import {
    setParametrosPesquisa as setParametrosPesquisaAction,
    limparParametrosPesquisa as limparParametrosPesquisaAction
} from '../../reducers/actions/index';
import {
    SttGrid,
    SttButton,
    SttInput,
    SttMaskedInput,
    SttDatePicker,
    SttFormControl,
    SttFormGroup,
    SttFormControlLabel,
    SttCheckbox,
    SttAutocomplete,
    SttTranslateHook
} from '@stt-componentes/core';

import {
    NUMERO_PEDIDO,
    DATA_INICIAL,
    DATA_FINAL,
    UF_ORIGEM,
    CIDADE_ORIGEM,
    UF_DESTINO,
    CIDADE_DESTINO,
    NOME_PACIENTE,
    DATA_NASCIMENTO,
    CPF_PACIENTE,
    SITUACAO,
    ATRASADOS,
    REGULADOR,
    AVALIADOR,
    REGIONAL
} from './fieldNames';

import { temPerfil } from '../../security/acl';
import validationSchema from './validationSchema';

const { LABEL } = SITUACAO_PROCESSO;

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    },
    laudoPorVencer: {
        backgroundColor: '#F7FC75'
    },
    laudoVencido: {
        backgroundColor: '#D88080'
    }
}));

const situacoesOrdenadas = () => {
    const filtros = [...SITUACAO_PROCESSO_FILTRO]

    filtros.sort((a, b) => {
        if (a.descricao < b.descricao) return -1;
        if (a.descricao > b.descricao) return 1;
        return 0;
    })

    return filtros;
}

const FormPesquisa = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const {
        user,
        setParametrosPesquisa,
        limparParametrosPesquisa,
        parametrosPesquisa,
        callbackAndamento,
        callbackLimpar,
        page,
        count,
        orderBy,
        order,
        callbackBusca,
        buscar,
    } = props;
    const classes = useStyles();
    const TFD_API_BASE_URL = global.gConfig.url_base_tfd;
    const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;
    const formRef = useRef();
    const schema = validationSchema(strings);

    //Filtros
    const [estados, setEstados] = useState(null);
    const [cidadesOrigem, setCidadesOrigem] = useState([]);
    const [cidadesDestino, setCidadesDestino] = useState([]);

    //Tratamento de filtro para regulador e avaliador
    const [mostrarFiltroRegulador, setMostrarFiltroRegulador] = useState(false);
    const [mostrarFiltroAvaliador, setMostrarFiltroAvaliador] = useState(false);
    const [mostrarCidadeAgendadora, setMostrarCidadeAgendadora] = useState(false);

    const [reguladores, setReguladores] = useState(null);
    const [avaliadores, setAvaliadores] = useState(null);

    const [regionais, setRegionais] = useState(null);

    useEffect(() => {
        let situacaoPerfil = situacao.TODOS;

        if (temPerfil(user, PERFIS.TFD_ESTADUAL)) {
            situacaoPerfil = situacao.AGUARDANDO_AVALIACAO_TFD_ESTADUAL;
        }

        if (temPerfil(user, PERFIS.TFD_REGULADOR)) {
            situacaoPerfil = situacao.AGUARDANDO_AVALIACAO_TFD_REGULADOR;
        }

        SITUACAO_PROCESSO_FILTRO.forEach(s => {
            if (s.id === situacaoPerfil) {
                estadoInicial['situacao'] = s;
            }
        });
        setParametrosPesquisa(estadoInicial);
    }, [estadoInicial, user]);

    useEffect(() => {
        if (buscar && formRef.current) {
            formRef.current.handleSubmit();
        }
    }, [buscar]);

    useEffect(() => {
        axios
            .get(`${UTILITARIOS_API_BASE_URL}/localizacao/estado`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    setEstados(itens);
                }
            })
            .catch(err => {
                console.log(err);
                setEstados([]);
            });

        const paramsTfdRegulador = { perfis: JSON.stringify([PERFIS.TFD_REGULADOR]) };
        axios
            .get(`${UTILITARIOS_API_BASE_URL}/funcionario`, { params: paramsTfdRegulador, headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    setReguladores(itens);
                }
            })
            .catch(err => {
                console.log(err);
                setReguladores([]);
            });

        const paramsTfdAvaliador = { perfis: JSON.stringify([PERFIS.TFD_AVALIADOR]) };
        axios
            .get(`${UTILITARIOS_API_BASE_URL}/funcionario`, { params: paramsTfdAvaliador, headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    setAvaliadores(itens);
                }
            })
            .catch(err => {
                console.log(err);
                setAvaliadores([]);
            });

        axios
            .get(`${UTILITARIOS_API_BASE_URL}/regional-saude`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    setRegionais(itens);
                }
            })
            .catch(err => {
                console.log(err);
                setRegionais([]);
            });
    }, []);

    const estruturarFiltros = (dados) => {
        const filtros = {};
        const offset = (page * count);

        filtros.count = count;
        filtros.start = offset;
        filtros.sort = orderBy;
        filtros.direction = order;

        if (dados.numero_pedido) {
            filtros.numero_pedido = dados.numero_pedido.trim();
        }

        if (dados.data_inicial) {
            filtros.data_inicial = moment(dados.data_inicial).format('YYYY-MM-DD');
        }

        if (dados.data_final) {
            filtros.data_final = moment(dados.data_final).format('YYYY-MM-DD');
        }

        if (dados.uf_origem) {
            filtros.uf_origem = dados.uf_origem.id;
        }

        if (dados.cidade_origem) {
            filtros.cidade_origem = dados.cidade_origem.id;
        }

        if (dados.uf_destino) {
            filtros.uf_destino = dados.uf_destino.id;
        }

        if (dados.cidade_destino) {
            filtros.cidade_destino = dados.cidade_destino.id;
        }

        if (dados.nome_paciente) {
            filtros.nome_paciente = dados.nome_paciente.trim();
        }

        if (dados.data_nascimento) {
            filtros.data_nascimento = moment(dados.data_nascimento).format('YYYY-MM-DD');
        }

        if (dados.data_nascimento) {
            filtros.data_nascimento = moment(dados.data_nascimento).format('YYYY-MM-DD');
        }

        if (dados.cpf_paciente) {
            filtros.cpf_paciente = ('00000000000' + dados.cpf_paciente.replace(/[\. ,:-]+/g, "")).slice(-11);
        }

        if (dados.situacao && dados.situacao.id != situacao.TODOS) {
            filtros.situacao = dados.situacao.id;
        }

        if (dados.regulador) {
            filtros.id_funcionario_regulador = dados.regulador.id;
        }

        if (dados.regional) {
            filtros.regional = dados.regional.id;
        }

        if (dados.avaliador) {
            filtros.id_funcionario_avaliador = dados.avaliador.id;
        }

        filtros.atrasados = dados.atrasados;

        //Filtros passivos por perfil
        if (temPerfil(user, PERFIS.TFD_MUNICIPAL)) {
            filtros.id_funcionario_tecnico = user.idFuncionario;
        }

        if (temPerfil(user, PERFIS.TFD_REGULADOR)) {
            filtros.id_funcionario_regulador = user.idFuncionario;
        }

        if (temPerfil(user, PERFIS.TFD_AVALIADOR)) {
            filtros.id_funcionario_avaliador = user.idFuncionario;
        }

        if (temPerfil(user, PERFIS.TFD_AGENDADOR)) {
            filtros.id_funcionario_agendador = user.idFuncionario;
            filtros.cidades_agendadoras = true;
        }

        filtros.id_funcionario = user.idFuncionario;

        setParametrosPesquisa(dados);
        return filtros;
    }

    const handleChangeUf = (uf, callbackSetCidades) => {
        if (uf) {
            callbackSetCidades(null);
            axios
                .get(`${UTILITARIOS_API_BASE_URL}/localizacao/cidade?id-estado=${uf.id}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        callbackSetCidades(itens);
                    } else {
                        callbackSetCidades([]);
                    }
                })
                .catch(err => {
                    console.log(err);
                    callbackSetCidades([]);
                });
        }
    }

    const handleChangeSituacao = (s) => {
        if (s && (s.id == situacao.SENDO_ATUALIZADO_TFD_REGULADOR || s.id == situacao.AGUARDANDO_AVALIACAO_TFD_REGULADOR)) {
            setMostrarFiltroRegulador(true);
            setMostrarFiltroAvaliador(false);
        } else if (s && (s.id == situacao.SENDO_ATUALIZADO_AVALIADOR || s.id == situacao.AGUARDANDO_AVALIACAO_AVALIADOR)) {
            setMostrarFiltroAvaliador(true);
            setMostrarFiltroRegulador(false);
        } else {
            setMostrarFiltroAvaliador(false);
            setMostrarFiltroRegulador(false);
        }

        if (s && s.id == situacao.AGUARDANDO_AGENDAMENTO_PELO_AGENDADOR) {
            setMostrarCidadeAgendadora(true);
        }
    }

    const tratarSolicitacoes = (solicitacoes) => {
        solicitacoes.map((solicitacao) => {
            let situacaoFormatada = LABEL[solicitacao.situacao];

            if (mostrarFiltroRegulador && solicitacao.nome_regulador) {
                situacaoFormatada += ` - ${solicitacao.nome_regulador}`;
            }

            if (mostrarFiltroAvaliador && solicitacao.nome_avaliador) {
                situacaoFormatada += ` - ${solicitacao.nome_avaliador}`;
            }

            if (mostrarCidadeAgendadora && solicitacao.id_cidade_origem && solicitacao.id_tfd_cadastro_cidade_agendadora) {
                situacaoFormatada += ` - ${solicitacao.descricao_cidade_agendadora}`;
            }

            solicitacao.situacaoFormatada = situacaoFormatada;
        });

        return solicitacoes;
    }

    return (
        <>
            {
                <Formik
                    innerRef={formRef}
                    initialValues={parametrosPesquisa}
                    validationSchema={schema}
                    onSubmit={(data, { setSubmitting }) => {
                        let params = estruturarFiltros(data);
                        callbackAndamento(true);
                        axios
                            .get(`${TFD_API_BASE_URL}/solicitacao`, { params: params, headers: getHeaders() })
                            .then((response) => {
                                const dados = response.data.data;
                                dados.itens = tratarSolicitacoes(dados.itens);
                                callbackBusca(dados);
                            })
                            .catch(err => {
                                callbackBusca(
                                    {
                                        totalRegistros: 0,
                                        itens: []
                                    }
                                );
                            })
                            .finally(() => {
                                callbackAndamento(false);
                                setSubmitting(false);
                            });
                    }}
                >
                    {
                        ({
                            values,
                            isSubmitting,
                            handleSubmit,
                            resetForm,
                            errors,
                            isValid
                        }) => {
                            return (
                                <>
                                    <form onSubmit={handleSubmit} noValidate onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleSubmit();
                                        }
                                    }}>
                                        <SttGrid container spacing={3}>
                                            {
                                                <>
                                                    <SttGrid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                        <Field name={NUMERO_PEDIDO}>
                                                            {({
                                                                field,
                                                                meta
                                                            }) => (
                                                                <SttInput
                                                                    {...field}
                                                                    inputProps={{
                                                                        maxLength: 20,
                                                                        style: { textTransform: "uppercase" }
                                                                    }}
                                                                    label={strings.numeroPedido}
                                                                    value={field.value ? field.value : ''}
                                                                    // inputProps={{ style: { textTransform: "uppercase" } }}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={4} lg={2} xl={2}>
                                                        <Field name={DATA_INICIAL}>
                                                            {({
                                                                field: { name, value, },
                                                                form: { setFieldValue, setFieldError, setFieldTouched },
                                                                meta
                                                            }) => {
                                                                return (
                                                                    <SttDatePicker
                                                                        label={strings.dataInicial}
                                                                        inputprops={{
                                                                            name: name
                                                                        }}
                                                                        disableFuture
                                                                        maxDate={new Date()}
                                                                        error={meta.touched && meta.error ? true : false}
                                                                        value={value}
                                                                        onBlur={() => {
                                                                            setFieldTouched(DATA_INICIAL, true);
                                                                        }}
                                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                        onChange={date => {
                                                                            setFieldValue(DATA_INICIAL, date, true);
                                                                        }
                                                                        }
                                                                        onClose={() => setFieldTouched(DATA_INICIAL, true)}
                                                                    />
                                                                );
                                                            }}
                                                        </Field>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={4} lg={2} xl={2}>
                                                        <Field name={DATA_FINAL}>
                                                            {({
                                                                field: { name, value, },
                                                                form: { setFieldValue, setFieldError, setFieldTouched },
                                                                meta
                                                            }) => {
                                                                return (
                                                                    <SttDatePicker
                                                                        label={strings.dataFinal}
                                                                        inputprops={{
                                                                            name: name
                                                                        }}
                                                                        disableFuture
                                                                        maxDate={new Date()}
                                                                        error={meta.touched && meta.error ? true : false}
                                                                        value={value}
                                                                        onBlur={() => {
                                                                            setFieldTouched(DATA_FINAL, true);
                                                                        }}
                                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                        onChange={date => setFieldValue(DATA_FINAL, date, true)}
                                                                        onClose={() => setFieldTouched(DATA_FINAL, true)}
                                                                    />
                                                                );
                                                            }}
                                                        </Field>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={3} lg={2}>
                                                        <Field name={UF_ORIGEM}>
                                                            {({
                                                                field: { name, value, onBlur },
                                                                form: { setFieldValue }
                                                            }) => (
                                                                <SttAutocomplete
                                                                    inputprops={{
                                                                        name: name,
                                                                        label: strings.ufOrigem
                                                                    }}
                                                                    getOptionLabel={option => option && option.sigla || ''}
                                                                    getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.id === valueOptionSelected.id}
                                                                    options={estados}
                                                                    carregando={!estados}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(e, item) => {
                                                                        setFieldValue(UF_ORIGEM, item || null);
                                                                        setFieldValue(CIDADE_ORIGEM, null);
                                                                        handleChangeUf(item, setCidadesOrigem);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>
                                                    <SttGrid item xs={12} sm={6} md={3} lg={3} xl={2}>
                                                        <Field name={CIDADE_ORIGEM}>
                                                            {({
                                                                field: { name, value, onBlur },
                                                                form: { setFieldValue }
                                                            }) => (
                                                                <SttAutocomplete
                                                                    inputprops={{
                                                                        name: name,
                                                                        label: strings.cidadeOrigem
                                                                    }}
                                                                    disabled={!values.uf_origem}
                                                                    getOptionLabel={option => option && option.nome || ''}
                                                                    getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.id === valueOptionSelected.id}
                                                                    options={cidadesOrigem}
                                                                    carregando={!cidadesOrigem}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(e, item) => {
                                                                        setFieldValue(CIDADE_ORIGEM, item || null);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={3} lg={2}>
                                                        <Field name={UF_DESTINO}>
                                                            {({
                                                                field: { name, value, onBlur },
                                                                form: { setFieldValue }
                                                            }) => (
                                                                <SttAutocomplete
                                                                    inputprops={{
                                                                        name: name,
                                                                        label: strings.ufDestino
                                                                    }}
                                                                    getOptionLabel={option => option && option.sigla || ''}
                                                                    getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.id === valueOptionSelected.id}
                                                                    options={estados}
                                                                    carregando={!estados}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(e, item) => {
                                                                        setFieldValue(UF_DESTINO, item || null);
                                                                        setFieldValue(CIDADE_DESTINO, null);
                                                                        handleChangeUf(item, setCidadesDestino);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={3} lg={3} xl={2}>
                                                        <Field name={CIDADE_DESTINO}>
                                                            {({
                                                                field: { name, value, onBlur },
                                                                form: { setFieldValue }
                                                            }) => (
                                                                <SttAutocomplete
                                                                    inputprops={{
                                                                        name: name,
                                                                        label: strings.cidadeDestino
                                                                    }}
                                                                    disabled={!values.uf_destino}
                                                                    getOptionLabel={option => option && option.nome || ''}
                                                                    getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.id === valueOptionSelected.id}
                                                                    options={cidadesDestino}
                                                                    carregando={!cidadesDestino}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(e, item) => {
                                                                        setFieldValue(CIDADE_DESTINO, item || null);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={3} lg={3} xl={2}>
                                                        <Field name={REGIONAL}>
                                                            {({
                                                                field: { name, value, onBlur },
                                                                form: { setFieldValue }
                                                            }) => (
                                                                <SttAutocomplete
                                                                    inputprops={{
                                                                        name: name,
                                                                        label: strings.regionalSaude
                                                                    }}
                                                                    getOptionLabel={option => (option && option.descricao) || ''}
                                                                    getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.id === valueOptionSelected.id}
                                                                    options={regionais}
                                                                    carregando={!regionais}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(e, item) => {
                                                                        const reg = item || null;
                                                                        setFieldValue(REGIONAL, reg);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={4} lg={4} xl={2}>
                                                        <FastField name={NOME_PACIENTE}>
                                                            {({
                                                                field,
                                                                meta
                                                            }) => (
                                                                <SttInput
                                                                    {...field}
                                                                    label={strings.paciente}
                                                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                                                    value={field.value ? field.value : ''}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                />
                                                            )}
                                                        </FastField>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                        <Field name={DATA_NASCIMENTO}>
                                                            {({
                                                                field: { name, value, },
                                                                form: { setFieldValue, setFieldError, setFieldTouched },
                                                                meta
                                                            }) => {
                                                                return (
                                                                    <SttDatePicker
                                                                        label={strings.dataNascimento}
                                                                        inputprops={{
                                                                            name: name
                                                                        }}
                                                                        disableFuture
                                                                        maxDate={new Date()}
                                                                        error={meta.touched && meta.error ? true : false}
                                                                        value={value}
                                                                        onBlur={() => {
                                                                            setFieldTouched(DATA_NASCIMENTO, true);
                                                                        }}
                                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                        onChange={date => setFieldValue(DATA_NASCIMENTO, date, true)}
                                                                        onClose={() => setFieldTouched(DATA_NASCIMENTO, true)}
                                                                    />
                                                                );
                                                            }}
                                                        </Field>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                        <FastField name={CPF_PACIENTE}>
                                                            {({
                                                                field,
                                                                meta,
                                                            }) => (
                                                                <SttMaskedInput
                                                                    {...field}
                                                                    mask="cpf"
                                                                    label={strings.cpf}
                                                                    required
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                />
                                                            )}
                                                        </FastField>
                                                    </SttGrid>

                                                    <SttGrid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                        <Field name={SITUACAO}>
                                                            {({
                                                                field: { name, value, onBlur },
                                                                form: { setFieldValue }
                                                            }) => (
                                                                <SttAutocomplete
                                                                    inputprops={{
                                                                        name: name,
                                                                        label: strings.situacao
                                                                    }}
                                                                    getOptionLabel={option => option && option.descricao || ''}
                                                                    getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.id === valueOptionSelected.id}
                                                                    options={situacoesOrdenadas()}
                                                                    value={value}
                                                                    onBlur={onBlur}
                                                                    onChange={(e, item) => {
                                                                        if (item) {
                                                                            setFieldValue(SITUACAO, item);
                                                                        } else {
                                                                            let situacaoPerfil = situacao.TODOS;

                                                                            if (temPerfil(user, PERFIS.TFD_ESTADUAL)) {
                                                                                situacaoPerfil = situacao.AGUARDANDO_AVALIACAO_TFD_ESTADUAL;
                                                                            }

                                                                            if (temPerfil(user, PERFIS.TFD_REGULADOR)) {
                                                                                situacaoPerfil = situacao.AGUARDANDO_AVALIACAO_TFD_REGULADOR;
                                                                            }

                                                                            SITUACAO_PROCESSO_FILTRO.forEach(s => {
                                                                                if (s.id === situacaoPerfil) {
                                                                                    setFieldValue(SITUACAO, s);
                                                                                }
                                                                            });
                                                                        }
                                                                        handleChangeSituacao(item);
                                                                        setFieldValue(REGULADOR, null);
                                                                        setFieldValue(AVALIADOR, null);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </SttGrid>

                                                    {
                                                        mostrarFiltroRegulador &&
                                                        <SttGrid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                            <Field name={REGULADOR}>
                                                                {({
                                                                    field: { name, value, onBlur },
                                                                    form: { setFieldValue }
                                                                }) => (
                                                                    <SttAutocomplete
                                                                        inputprops={{
                                                                            name: name,
                                                                            label: strings.regulador
                                                                        }}
                                                                        getOptionLabel={option => option && option.nome || ''}
                                                                        getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.id === valueOptionSelected.id}
                                                                        options={reguladores}
                                                                        carregando={!reguladores}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        onChange={(e, item) => {
                                                                            setFieldValue(REGULADOR, item || null);
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </SttGrid>
                                                    }

                                                    {
                                                        mostrarFiltroAvaliador &&
                                                        <SttGrid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                            <Field name={AVALIADOR}>
                                                                {({
                                                                    field: { name, value, onBlur },
                                                                    form: { setFieldValue }
                                                                }) => (
                                                                    <SttAutocomplete
                                                                        inputprops={{
                                                                            name: name,
                                                                            label: strings.avaliador
                                                                        }}
                                                                        getOptionLabel={option => option && option.nome || ''}
                                                                        getOptionSelected={(option, valueOptionSelected) => option && valueOptionSelected && option.id === valueOptionSelected.id}
                                                                        options={avaliadores}
                                                                        carregando={!avaliadores}
                                                                        value={value}
                                                                        onBlur={onBlur}
                                                                        onChange={(e, item) => {
                                                                            setFieldValue(AVALIADOR, item || null);
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </SttGrid>
                                                    }
                                                    {
                                                        <SttGrid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                            <Field name={ATRASADOS}>
                                                                {({
                                                                    field,
                                                                    form: { setFieldValue }
                                                                }) => (
                                                                    <SttFormControl variant="outlined">
                                                                        <SttFormGroup row>
                                                                            <SttFormControlLabel
                                                                                control={
                                                                                    <SttCheckbox
                                                                                        {...field}
                                                                                        value={true}
                                                                                        color="primary"
                                                                                        checked={values[ATRASADOS]}
                                                                                        onChange={event => {
                                                                                            if (event.target.checked) {
                                                                                                setFieldValue(ATRASADOS, true);
                                                                                            } else {
                                                                                                setFieldValue(ATRASADOS, false);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                label={strings.mostrarProcessosVencidos}
                                                                            />
                                                                        </SttFormGroup>
                                                                    </SttFormControl>
                                                                )}
                                                            </Field>
                                                        </SttGrid>
                                                    }
                                                </>
                                            }
                                        </SttGrid>
                                        <SttGrid container spacing={3}>
                                            <SttGrid item xs={12} className={classes.buttonWrapper}>

                                                <SttButton
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        callbackLimpar();
                                                    }}
                                                    disabled={isSubmitting || !isValid}
                                                    nomarginleft="true"
                                                >
                                                    {strings.pesquisar}
                                                </SttButton>
                                                <SttButton
                                                    type="button"
                                                    variant="outlined"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        limparParametrosPesquisa();
                                                        callbackLimpar();
                                                        resetForm({
                                                            values: estadoInicial
                                                        });
                                                        handleChangeSituacao(null);
                                                    }}
                                                >
                                                    {strings.limpar}
                                                </SttButton>
                                            </SttGrid>
                                        </SttGrid>
                                    </form>
                                </>
                            )
                        }
                    }
                </Formik>
            }
        </>
    )
}

FormPesquisa.propTypes = {
    user: PropTypes.object.isRequired,
    setParametrosPesquisa: PropTypes.func.isRequired,
    limparParametrosPesquisa: PropTypes.func.isRequired,
    parametrosPesquisa: PropTypes.object.isRequired,
    callbackAndamento: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    orderBy: PropTypes.string,
    order: PropTypes.string.isRequired,
    callbackBusca: PropTypes.func.isRequired,
    buscar: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        parametrosPesquisa: state.index.parametrosPesquisa
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setParametrosPesquisa: parametros => dispatch(setParametrosPesquisaAction(parametros)),
        limparParametrosPesquisa: () => dispatch(limparParametrosPesquisaAction())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormPesquisa);