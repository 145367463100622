import { PERFIS, situacao, TIPO_PROCESSO, PERMISSOES } from './Constants';
import { temPerfil, temPermissaoRBAC } from '../security/acl';

const definirAcoes = (user, tfd) => {
    let podeEditar = false;
    let podeSolicitarTransporte = false;
    let necessitaProcedimentoSolicitado = false;
    let podeDevolverTfd = false;
    let podeCancelarTfd = false;
    let podeNegarTfd = false;
    let podeEncaminharTfd = false;
    let podeAguardarAgendamento = false;
    let podeConfirmarAgendamento = false;
    let podeAguardarAgendamentoCNRAC = false;
    let podeInformarNumeroCNRAC = false;
    let podeEncaminharTfdEstadual = false;
    let podeRegular = false;
    let podeAnexarComprovanteComparecimento = false;
    let podeArquivarProcesso = false;
    let podeSinalizarPendencia = false;

    //Verifica se o usuário com perfil tfdEstadual não necessita inserir o procedimento solicitado
    if (
        temPermissaoRBAC(user, PERMISSOES.INSERIR_PROCEDIMENTO) &&
        tfd['tipo_processo'] !== TIPO_PROCESSO.JUDICIAL &&
        !(tfd.id_tfd_cadastro_procedimento_solicitado) &&
        tfd.situacao === situacao.AGUARDANDO_AVALIACAO_TFD_ESTADUAL
    ) {
        necessitaProcedimentoSolicitado = true;

        return {
            podeEditar,
            podeSolicitarTransporte,
            necessitaProcedimentoSolicitado,
            podeDevolverTfd,
            podeCancelarTfd,
            podeNegarTfd,
            podeEncaminharTfd,
            podeAguardarAgendamento,
            podeConfirmarAgendamento,
            podeAguardarAgendamentoCNRAC,
            podeInformarNumeroCNRAC,
            podeEncaminharTfdEstadual,
            podeRegular,
            podeAnexarComprovanteComparecimento,
            podeArquivarProcesso,
            podeSinalizarPendencia
        };
    }

    if (tfd.situacao !== situacao.AUTORIZADO && temPermissaoRBAC(user, PERMISSOES.CRIAR)) {
        if (temPerfil(user, PERFIS.TFD_MUNICIPAL)) {
            if (tfd.situacao === situacao.RASCUNHO || tfd.situacao === situacao.DEVOLVIDO) {
                podeEditar = true;
            }
        }

        if (temPerfil(user, PERFIS.ADMINISTRADOR) || temPerfil(user, PERFIS.ADMIN_TELEMEDICINA)) {
            podeEditar = true;
        }

        if (temPerfil(user, PERFIS.TFD_REGULADOR)) {
            if (tfd.situacao === situacao.SENDO_ATUALIZADO_TFD_REGULADOR || tfd.situacao === situacao.AGUARDANDO_AVALIACAO_TFD_REGULADOR) {
                podeEditar = true;
            }
        }

        if (temPerfil(user, PERFIS.TFD_ESTADUAL)) {
            if (tfd.situacao === situacao.SENDO_ATUALIZADO_TFD_ESTADUAL ||
                tfd.situacao === situacao.AGUARDANDO_AVALIACAO_TFD_ESTADUAL ||
                tfd.situacao === situacao.SENDO_ATUALIZADO_NOVAMENTE_TFD_ESTADUAL ||
                tfd.situacao === situacao.AGUARDANDO_NOVAMENTE_AVALIACAO_TFD_ESTADUAL ||
                tfd.situacao === situacao.AGUARDANDO_ENVIO_CNRAC ||
                tfd.situacao === situacao.AGUARDANDO_AGENDAMENTO ||
                tfd.situacao === situacao.RASCUNHO ||
                tfd.situacao === situacao.SENDO_ATUALIZADO_CNRAC_TFD_ESTADUAL ||
                tfd.situacao === situacao.AGUARDANDO_AGENDAMENTO_TFD_ESTADUAL ||
                tfd.situacao === situacao.SENDO_ATUALIZADO_AGENDAMENTO_TFD_ESTADUAL) {
                podeEditar = true;
            }
        }

        if (temPerfil(user, PERFIS.TFD_AGENDADOR)) {
            if (tfd.situacao === situacao.AGUARDANDO_AGENDAMENTO_PELO_AGENDADOR ||
                tfd.situacao === situacao.SENDO_ATUALIZADO_PELO_AGENDADOR ||
                tfd.situacao === situacao.RASCUNHO ||
                tfd.situacao === situacao.REGISTRADO_SISREG) {
                podeEditar = true;
            }
        }

        if (temPerfil(user, PERFIS.TFD_AVALIADOR)) {
            if (tfd.situacao === situacao.SENDO_ATUALIZADO_AVALIADOR ||
                tfd.situacao === situacao.AGUARDANDO_AVALIACAO_AVALIADOR) {
                podeEditar = true;
            }
        }
    }

    //Solicitação de transporte
    /**
     * 
     */
    if (
        temPermissaoRBAC(user, PERMISSOES.SOLICITAR_TRANSPORTE) &&
        tfd.situacao === situacao.AUTORIZADO
        && (tfd['tipo_processo'] === TIPO_PROCESSO.INTERESTADUAL || tfd['tipo_processo'] === TIPO_PROCESSO.JUDICIAL)
    ) {
        var dataProcesso = new Date(tfd['data_preenchimento_laudo_medico']);
        var diferenca = Math.abs(dataProcesso - new Date());
        var diferencaAnos = Math.floor(diferenca / (1000 * 365 * 60 * 60 * 24));

        if (diferencaAnos < 1) {
            podeSolicitarTransporte = true;
        }

        //Caso o tipo de processo for JUDICIAL, ignora a regra de 1 ano para solicitação de transportes do laudo médico
        if (tfd['tipo_processo'] === TIPO_PROCESSO.JUDICIAL) {
            podeSolicitarTransporte = true;
        }
    }

    //Arquivar processo de TFD
    /**
     * Para arquivar um processo de TFD, o usuário deve ter a permissão de ARQUIVAR PROCESSOS, o processo deve ser JUDICIAL,
     * estar AUTORIZADO. O processo deve sair da listagem do usuário e só reaparecer caso essa situação
     * seja filtrada na listagem
     */
    if (
        temPermissaoRBAC(user, PERMISSOES.ARQUIVAR_PROCESSO) &&
        tfd['tipo_processo'] === TIPO_PROCESSO.JUDICIAL &&
        tfd.situacao === situacao.AUTORIZADO
    ) {
        podeArquivarProcesso = true;
    }


    //Devolução
    /**
     * Situações: aguardando agendamento pelo agendador (5), aguardando avaliação do tfd estadual (15), aguardando avaliação novamente do TFD estadual (23) ou registrado no SISREG (10)
     * Perfil tfdAgendador ou tfdEstadual
     */
    if (temPermissaoRBAC(user, PERMISSOES.DEVOLVER)) {
        if (tfd.situacao === situacao.AGUARDANDO_AGENDAMENTO_PELO_AGENDADOR) {
            podeDevolverTfd = true;
        }

        if (
            tfd.situacao === situacao.AGUARDANDO_AVALIACAO_TFD_ESTADUAL
            || tfd.situacao === situacao.AGUARDANDO_NOVAMENTE_AVALIACAO_TFD_ESTADUAL
            || tfd.situacao === situacao.REGISTRADO_SISREG
            || tfd.situacao === situacao.AGUARDANDO_AGENDAMENTO_TFD_ESTADUAL
        ) {
            podeDevolverTfd = true;
        }
    }

    if (
        (tfd.situacao !== situacao.NEGADO && tfd.situacao !== situacao.CANCELADO && tfd.situacao !== situacao.RASCUNHO) &&
        (temPerfil(user, PERFIS.TFD_ESTADUAL) || temPerfil(user, PERFIS.TFD_REGULADOR) || temPerfil(user, PERFIS.ADMINISTRADOR) || temPerfil(user, PERFIS.ADMIN_TELEMEDICINA))
    ) {
        podeSinalizarPendencia = true;
    }

    //Cancelamento
    /**
     * Pode ser cancelado sempre que o processo não está autorizado ou cancelado e se for um tecnico
     */
    if ((tfd.situacao !== situacao.AUTORIZADO && tfd.situacao !== situacao.CANCELADO) && temPerfil(user, PERFIS.TFD_MUNICIPAL)) {
        podeCancelarTfd = true;
    }

    //Negação
    /**
     * Pode ser negado caso a situação do processo não for negado ou cancelado e caso seja um estadual ou avaliador
     */
    if ((tfd.situacao !== situacao.ARQUIVADO && tfd.situacao !== situacao.NEGADO && tfd.situacao !== situacao.CANCELADO && tfd.situacao !== situacao.AUTORIZADO) && temPermissaoRBAC(user, PERMISSOES.NEGAR)) {
        podeNegarTfd = true;
    }

    //Encaminhamento
    /**
     * Pode ser feito pelo tfdAgendador ou pelo tfdAvaliador
     * 
     * tfdAgendador: o processo deve estar na situação aguardando agendamento pelo agendador (5), registrado no SISREG (10)
     */
    if ((tfd.situacao === situacao.AGUARDANDO_AGENDAMENTO_PELO_AGENDADOR || tfd.situacao === situacao.REGISTRADO_SISREG) &&
        (temPerfil(user, PERFIS.TFD_AGENDADOR) || temPerfil(user, PERFIS.TFD_AVALIADOR))) {
        podeEncaminharTfd = true;
    }

    //Encaminhamento
    /**
     * Pode ser feito pelo tfdEstadual
     * 
     * tfdEstadual: o processo deve estar na situação aguardando avaliação do regulador (20), aguardando avaliação do avaliador (30), aguardando avaliação do tfd estadual (15), aguardando novamente avaliação do tfd estadual (23), ou aguardando envio da cnrac (35)
     */
    if (
        (
            tfd.situacao === situacao.AGUARDANDO_AVALIACAO_TFD_REGULADOR ||
            tfd.situacao === situacao.AGUARDANDO_AVALIACAO_AVALIADOR ||
            tfd.situacao === situacao.AGUARDANDO_AVALIACAO_TFD_ESTADUAL ||
            tfd.situacao === situacao.AGUARDANDO_AGENDAMENTO_TFD_ESTADUAL ||
            tfd.situacao === situacao.AGUARDANDO_NOVAMENTE_AVALIACAO_TFD_ESTADUAL
        ) &&
        (temPerfil(user, PERFIS.TFD_ESTADUAL)) &&
        temPermissaoRBAC(user, PERMISSOES.ENCAMINHAR)
    ) {
        podeEncaminharTfdEstadual = true;
    }

    //Agendamento da CNRAC
    /**
     * Pode ser feito pelo tfdEstadual
     * 
     * o processo deve estar na situação em atualização tfd estadual (13), aguardando avaliação do tfd estadual (15),
     * em atualização novamente tfd estadual (23), aguardando novamente avaliação do tfd estadual (25),
     * aguardando envio da CNRAC ??? (35), em atualização cnrac tfd estadual (33), aguardando agendamento tfd estadual (40),
     * em atualização agendamento tfd estadual (37)
     */
    if ((tfd.situacao === situacao.SENDO_ATUALIZADO_TFD_ESTADUAL
        || tfd.situacao === situacao.AGUARDANDO_AVALIACAO_TFD_ESTADUAL
        || tfd.situacao === situacao.SENDO_ATUALIZADO_NOVAMENTE_TFD_ESTADUAL
        || tfd.situacao === situacao.AGUARDANDO_NOVAMENTE_AVALIACAO_TFD_ESTADUAL
        || tfd.situacao === situacao.SENDO_ATUALIZADO_CNRAC_TFD_ESTADUAL
        || tfd.situacao === situacao.SENDO_ATUALIZADO_AGENDAMENTO_TFD_ESTADUAL
        || tfd.situacao === situacao.AGUARDANDO_AGENDAMENTO_TFD_ESTADUAL) &&
        temPermissaoRBAC(user, PERMISSOES.ENCAMINHAR) &&
        (temPerfil(user, PERFIS.TFD_ESTADUAL)) && tfd.situacao !== situacao.AGUARDANDO_ENVIO_CNRAC) {
        podeAguardarAgendamentoCNRAC = true;
    }

    if ((tfd.situacao === situacao.SENDO_ATUALIZADO_TFD_ESTADUAL
        || tfd.situacao === situacao.AGUARDANDO_AVALIACAO_TFD_ESTADUAL
        || tfd.situacao === situacao.SENDO_ATUALIZADO_NOVAMENTE_TFD_ESTADUAL
        || tfd.situacao === situacao.AGUARDANDO_NOVAMENTE_AVALIACAO_TFD_ESTADUAL
        || tfd.situacao === situacao.SENDO_ATUALIZADO_CNRAC_TFD_ESTADUAL
        || tfd.situacao === situacao.SENDO_ATUALIZADO_AGENDAMENTO_TFD_ESTADUAL
        || tfd.situacao === situacao.AGUARDANDO_AGENDAMENTO_TFD_ESTADUAL) &&
        temPermissaoRBAC(user, PERMISSOES.ENCAMINHAR) &&
        (temPerfil(user, PERFIS.TFD_ESTADUAL)) && tfd.situacao !== situacao.AGUARDANDO_AGENDAMENTO) {
        podeAguardarAgendamento = true;
    }

    //Agendamento realizado
    /**
     * Pode ser feito pelo tfdEstadual
     * 
     * o processo deve estar na situação aguardando agendamento (32) e só pode ser feito pelo tfd estadual
     */

    if ((tfd.situacao === situacao.AGUARDANDO_AGENDAMENTO) && temPermissaoRBAC(user, PERMISSOES.INFORMAR_AGENDAMENTO)) {
        podeConfirmarAgendamento = true;
    }

    //Informar número da CNRAC
    /**
     * Pode ser feito pelo tfdEstadual
     * 
     * o processo deve estar na situação aguardando envio da CNRAC (35) e só pode ser feito pelo tfd estadual
     */

    if ((tfd.situacao === situacao.AGUARDANDO_ENVIO_CNRAC) && temPermissaoRBAC(user, PERMISSOES.INFORMAR_NUM_CNRAC)) {
        podeInformarNumeroCNRAC = true;
    }


    //Regulação
    /**
     * Feito somente pelo regulador???
     * 
     * O processo deve estar na situação aguardando avaliação do regulador (20)
     */
    if ((tfd.situacao === situacao.AGUARDANDO_AVALIACAO_TFD_REGULADOR) && temPermissaoRBAC(user, PERMISSOES.REGULAR)) {
        podeRegular = true;
    }

    //Encaminhar - Tfd Avaliador
    /**
     * Agendamento feito pelo avaliador.
     * 
     * O processo deve estar aguardando agendamento do tfd estadual (40) ou aguardando avaliação do avaliador (30)
     */
    if ((tfd.situacao === situacao.AGUARDANDO_AGENDAMENTO_TFD_ESTADUAL
        || tfd.situacao === situacao.AGUARDANDO_AVALIACAO_AVALIADOR) &&
        (temPerfil(user, PERFIS.TFD_AVALIADOR)) &&
        temPermissaoRBAC(user, PERMISSOES.ENCAMINHAR)) {
        podeEncaminharTfd = true;
    }

    //Comprovante de comparecimento
    /**
     * Reguladores, estaduais e técnicos podem anexar comprovantes de comparecimento
     * 
     * Processo deve estar autorizado (50) e ser do tipo interestadual
     */
    if (tfd.situacao === situacao.AUTORIZADO
        && (tfd['tipo_processo'] === TIPO_PROCESSO.INTERESTADUAL ||
            tfd['tipo_processo'] === TIPO_PROCESSO.JUDICIAL)
        && !temPerfil(user, PERFIS.TFD_AVALIADOR)
        && temPermissaoRBAC(user, PERMISSOES.ANEXAR_COMPRO_COMPAR)) {
        podeAnexarComprovanteComparecimento = true;
    }

    //Edição de processos judiciais
    if (
        temPermissaoRBAC(user, PERMISSOES.CRIAR) &&
        tfd.situacao === situacao.AUTORIZADO &&
        tfd['tipo_processo'] === TIPO_PROCESSO.JUDICIAL &&
        temPerfil(user, PERFIS.TFD_ESTADUAL) &&
        parseInt(tfd.qtt_solicitacao_passagem) === 0 &&
        parseInt(tfd.qtt_comprovante_comparecimento) === 0
    ) {
        podeEditar = true;
    }

    return {
        podeEditar,
        podeSolicitarTransporte,
        necessitaProcedimentoSolicitado,
        podeDevolverTfd,
        podeCancelarTfd,
        podeNegarTfd,
        podeEncaminharTfd,
        podeAguardarAgendamento,
        podeConfirmarAgendamento,
        podeAguardarAgendamentoCNRAC,
        podeInformarNumeroCNRAC,
        podeEncaminharTfdEstadual,
        podeRegular,
        podeAnexarComprovanteComparecimento,
        podeArquivarProcesso,
        podeSinalizarPendencia
    };
};

export default { definirAcoes };