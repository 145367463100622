import React, { useContext, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import ComponentePesquisa from '../../componentes/pesquisa';
import { SttTabs, SttTranslateHook } from '@stt-componentes/core';
import { useSignal, useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(3)
    }
}));

const Pesquisa = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const abas = useSignal([]);
    const abaAtiva = useSignal('0')

    const adicionarAba = (aba) => {
        let dadosAbas = [...abas.value];
        let { idAba } = aba;

        // Controle para evitar adição de aba repetida
        if (!dadosAbas.some((a) => a.idAba === idAba)) {
            dadosAbas.push(aba);
            abas.value = dadosAbas;
        }

        setTimeout(() => {
            abaAtiva.value = dadosAbas.findIndex((a) => a.idAba === idAba).toString();
        }, 120);
    };

    const recarregarAba = (aba, idAbaAntiga) => {
        let dadosAbas = [...abas.value];
        const index = dadosAbas.findIndex((a) => a.idAba === idAbaAntiga);
        if (index !== -1) {
            dadosAbas[index] = aba;
            abas.value = dadosAbas;
        }
    }

    const removerAba = (indice) => {
        abaAtiva.value = '0';
        const i = indice[0];

        const dadosAbas = [...abas.value.slice(0, i), ...abas.value.slice(i + 1)];

        abas.value = dadosAbas;
    };

    const removerAbaId = (idAba) => {
        const index = abas.value.findIndex((a) => a.idAba === idAba);
        if (index !== -1) {
            removerAba([index]);
        }
    };

    useEffect(() => {
        abas.value = [
            {
                titulo: strings.pesquisa,
                conteudo: ComponentePesquisa,
                key: 'Pesquisa',
                adicionarAba,
                recarregarAba,
                removerAba,
                removerAbaId,
                abas,
                permanente: true,
            },
        ];
    }, []);

    return (
        <SttTabs
            abas={abas.value}
            canClose={true}
            className={classes.wrapper}
            handleChangeAbaAtiva={(abaNova) => (abaAtiva.value = abaNova)}
            abaAtiva={abaAtiva.value}
            handleCloseTab={(indice) => removerAba(indice)}
            permanente={true}
            exigeConfirmacaoClose={false}
            contextMenu={true}
        />
    );
};
export default Pesquisa;